import React from "react"
import { Link } from "gatsby"
import Logo from '../images/logo.png';
import Search from './Search';
export default function Intro(props) {
    return (
        <React.Fragment>
            <div className="intro-section">
                <div className="container">
                    <div className="inner">
                        <h1> {props.title} </h1>
                        <div className="short_content" dangerouslySetInnerHTML={{ __html: props.description }} />
                        {props.search == "true" ?
                            <React.Fragment>
                                <div className="search_outer">
                                    <Search 
                                        search_data={props.search_data}
                                        searchType={props.searchType}>
                                    </Search>
                                </div>
                            </React.Fragment>
                            : ''}
                    </div>
                </div>
            </div>
            <a name="middle_content" id="middle_content"></a>
        </React.Fragment>
    )
}
