import React, { Component } from "react";
import { Link } from "gatsby";

class SearchPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            search: "",
            dataList: [],
            data: [],
            type: 0,
            typeEnter: false
        }
    }

    async componentDidMount() {
        this.getAllTeams();
    }

    componentWillReceiveProps(nextProps) {
        this.getAllTeams();
        this.setState({
            search: '',
            type: 0,
            typeEnter: false
        })
    }
    async getAllTeams() {
        if (this.props.searchType === 'blog') {
            fetch("https://tatva.com/backendapi/api/v1/cms/by-type/blog")
                .then(res => res.json())
                .then(
                    (result) => {
                        this.setState({
                            isLoaded: true,
                            data: result.data,
                            dataList: result.data
                        });
                    },
                    (error) => {
                        this.setState({
                            isLoaded: true,
                            error
                        });
                    }
                )

        } else {
            fetch("https://tatva.com/backendapi/api/v1/cms/by-type/team")
                .then(res => res.json())
                .then(
                    (result) => {
                        this.setState({
                            isLoaded: true,
                            data: result.data,
                            dataList: result.data
                        });
                    },
                    (error) => {
                        this.setState({
                            isLoaded: true,
                            error
                        });
                    }
                )
        }
    }

    renderCountry = country => {
        const { search, data, dataList } = this.state;
        var title = country.title.toLowerCase();
        console.log("country", country)
        return (
            <React.Fragment>
                {this.renderCountryTitle(country.title, country.slug, this.props.searchType === 'blog' ? country.category : JSON.parse(country.additional_fields))}
            </React.Fragment>
        );
    };

    renderCountryTitle = (title, slug, category) => {
        return (
            <div className="box">
                <div title={title}>
                    <Link to={this.props.searchType === 'blog' ? "/blog-details/" + slug + "/#middle_content" : "/team-details/" + slug + "/#middle_content"}>
                        {title} <em>{this.props.searchType === 'blog' ? category : category.designation} </em>
                    </Link>
                </div>
            </div>
        )
    }

    onchange = e => {
        console.log(e.target.value.length)
        this.setState({ search: e.target.value, type: e.target.value.length });
        if (e.target.value.length == 0) {
            this.setState({ typeEnter: false });
        } else {
            this.setState({ typeEnter: true });
        }
    };

    clear = () => {
        this.setState({
            search: '',
            type: 0,
            typeEnter: false
        })
    }

    search_redirect = (props) => {
        console.log("search_redirect", JSON.stringify(this.props))
        if (this.props.searchType === 'blog') {
            window.location.assign("/blog-by-search/" + this.state.search + "#middle_content")
        } else {
            window.location.assign("/team-by-search/" + this.state.search + "#middle_content")
        }


        // this.props.history.push("/team-by-search/"+this.state.searchText+"/");
    }

    render() {
        const { search, data, dataList, typeEnter, type } = this.state;
        const filteredCountries = data.filter(country => {
            return country.title.toLowerCase().indexOf(search.toLowerCase()) !== -1 || country.category.toLowerCase().indexOf(search.toLowerCase()) !== -1;
        });

        return (
            <React.Fragment>
                <input
                    className="form-control"
                    type="text"
                    placeholder={this.props.searchType === 'blog' ? 'Search by post title or category' : 'Search by name or designation'}
                    value={search}
                    onChange={this.onchange}
                />

                {
                    type >= 1 ? <button onClick={this.clear} className="search_close"></button> : ''
                }

                <button className="serach-icon" onClick={this.search_redirect}></button>
                <div className="clearfix"></div>
                {type == 0 ? ' ' :
                    <div className="box-container">
                        {filteredCountries.map(country => {
                            return this.renderCountry(country);
                        })}
                        <div className="clearboth"></div>
                        {filteredCountries.length == 0 && <span>No records found to display.</span>}
                    </div>
                }

            </React.Fragment>
        );
    }
}

export default SearchPage;
