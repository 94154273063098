import React from "react";
import { Link } from "gatsby";
import default_img from '../images/team-default.png';

export default function Team(props) {
    return (
        <React.Fragment>
            <Link to={props.ProfileLink + "/#middle_content"}>
                <div className={props.category_show == "true" ? 'team-item' : 'team-item'}>
                    <div className="inner">
                        <div className="profile">
                            {props.profile == null ?
                                <img src={default_img} title="" alt="" /> :
                                <img src={"https://tatva.com/backendapi/"+props.profile} title="" alt="" />
                            }
                        </div>
                        <h1> {props.title}</h1>
                        <p className="designation">{props.category.designation}</p>
                    </div>
                </div>
            </Link>
        </React.Fragment>
    )
}
