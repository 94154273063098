import React, { Component } from 'react';
import Layout from "../components/layout";
import Intro from "../components/intro";
import TeamItem from "../components/teamItem";
import { Link } from "gatsby";
import Nav from '../components/nav';

class IndexPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded: false,
            items: [],

            isCategoryLoaded: false,
            categoryContent: []
        }
    }

    async componentDidMount() {
        this.getAllTeams();
        this.getAllTeamCagory();
    }

    componentWillReceiveProps(nextProps) {
        this.getAllTeams();
        this.getAllTeamCagory();

    }

    async getAllTeamCagory() {
        fetch("https://tatva.com/backendapi/api/v1/cms-category/by-cms-type/team")
            .then(res => res.json())
            .then(
                (result) => {
                    const filteredDates = result.data.sort((a, b) => new Date(b.order_id) - new Date(a.order_id))
                    console.log("filteredDates", filteredDates)
                    this.setState({
                        isCategoryLoaded: true,
                        categoryContent: filteredDates
                    });
                },
                (error) => {
                    this.setState({
                        isCategoryLoaded: true,
                        error
                    });
                }
            )
    }

    async getAllTeams() {
        fetch("https://tatva.com/backendapi/api/v1/cms/by-type/team")
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({
                        isLoaded: true,
                        items: result
                    });
                },
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            )
    }

    render() {
        const { isLoaded, items, isCategoryLoaded, categoryContent } = this.state;
        return (
            <Layout>

                {isLoaded ?
                    <Nav
                        active="team"
                        pageTitle={'Team'}
                        title={''}
                        description={''}
                        keywords={''}
                    ></Nav>
                    : ''}
                    
                {isLoaded ?
                    <Intro
                        search="true"
                        search_data={items.data}
                        title="Our Team"
                        description="We have the largest team of professionals retained by a law firm in states of Telangana and Andhra Pradesh, with experience and expertise in diverse areas of practice.">

                    </Intro>
                    : ''}
                <div className="main-container" id="practice-navigation">
                    <div className="team-outer-image">
                        <div className="container">
                            <div className="row">
                                <div className="practice_navigation text-center">
                                    {isCategoryLoaded ?
                                        <ul>
                                            {categoryContent.map(item => (
                                                <li key={item._id}>
                                                    <Link activeClassName="active" to={"/team-by-category/" + item.slug + "/#middle_content"}>{item.title} asdasd</Link>
                                                </li>
                                            ))}
                                        </ul>
                                        : ''}
                                </div>
                            </div>

                            <div className="row our-team-outer">
                                <div className="col-md-12 col-sm-12">
                                    <div className="blog-container">
                                        {isLoaded ?
                                            <ul>
                                                {items.data.map(item => (
                                                    <TeamItem
                                                        key={item._id}
                                                        title={item.title}
                                                        slug={item.slug}
                                                        category={item.category}
                                                        profile={item.image}
                                                        ProfileLink={"/team-details/" + item.slug}
                                                    >
                                                    </TeamItem>
                                                ))}
                                            </ul>
                                            : ''}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        )
    }
}

export default IndexPage;

